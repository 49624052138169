define("discourse/plugins/discourse-coupa-people/discourse/api-initializers/nav-item", ["exports", "discourse/lib/api", "I18n"], function (_exports, _api, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _api.apiInitializer)("0.11", api => {
    api.addNavigationBarItem({
      name: "people",
      displayName: _I18n.default.t("coupa_people.heading"),
      href: "/people",
      classNames: ["people"],
      customFilter: (category, args) => {
        return !args.tagId && !args.category;
      }
    });
  });
});