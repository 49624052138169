define("discourse/plugins/discourse-coupa-people/discourse/components/cp-member", ["exports", "@ember/component", "@ember/object/computed"], function (_exports, _component, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNames: ["member"],
    displayName: (0, _computed.or)("member.name", "member.username")
  });
});