define("discourse/plugins/discourse-coupa-people/discourse/controllers/people", ["exports", "@ember/controller", "discourse-common/lib/debounce", "discourse-common/utils/decorators"], function (_exports, _controller, _debounce, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MIN_SEARCH_LENGTH = 3,
    PAGE_SIZE = 24;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    loading: false,
    canLoadNext: false,
    canLoadPrev: false,
    page: 0,
    roles: null,
    regions: null,
    industries: null,
    commodities: null,
    searchTerm: null,
    search() {
      if (this.searchTerm.length < MIN_SEARCH_LENGTH && this.searchTerm.length > 0) {
        return;
      }
      this.set("page", 0);
      this.set("term", this.searchTerm);
    },
    updateProps() {
      const canLoadMore = this.get("model.people").length === PAGE_SIZE;
      this.setProperties({
        canLoadNext: canLoadMore,
        roles: this.prepOptions(this.model.extras.roles),
        regions: this.prepOptions(this.model.extras.regions),
        industries: this.prepOptions(this.model.extras.industries),
        commodities: this.prepOptions(this.model.extras.commodities),
        searchTerm: this.term,
        canLoadPrev: this.page > 0
      });
    },
    prepOptions(values) {
      return values.map(value => {
        return {
          value,
          name: value
        };
      });
    },
    searchTermLongEnough(term) {
      return term && term.length >= MIN_SEARCH_LENGTH;
    },
    actions: {
      loadNext() {
        this.incrementProperty("page");
      },
      loadPrev() {
        this.decrementProperty("page");
      },
      dropdownChanged(type, value) {
        this.set("page", 0);
        this.set(type, value);
      },
      searchTermChanged() {
        (0, _debounce.default)(this, this.search, 500);
      },
      clearSearch() {
        this.set("page", 0);
        this.set("term", null);
      }
    }
  }, [["method", "updateProps", [(0, _decorators.observes)("model")]], ["method", "searchTermLongEnough", [(0, _decorators.default)("term")]]]));
});