define("discourse/plugins/discourse-coupa-people/discourse/connectors/discovery-list-container-top/forum-heading", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(attrs, component) {
      (0, _pluginApi.withPluginApi)("0.11", api => {
        api.onPageChange(url => {
          if (component.isDestroyed || component.isDestroying) {
            return;
          }
          if (url.match(/^\/c\/(.*)/)) {
            component.set("showHeading", false);
          } else {
            component.set("showHeading", true);
          }
        });
      });
    }
  };
});