define("discourse/plugins/discourse-coupa-people/discourse/templates/connectors/discovery-list-container-top/forum-heading", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if showHeading}}
    <h1>{{i18n "coupa_people.forums"}}</h1>
  {{/if}}
  */
  {
    "id": "s5qfu2e4",
    "block": "[[[41,[30,0,[\"showHeading\"]],[[[1,\"  \"],[10,\"h1\"],[12],[1,[28,[35,1],[\"coupa_people.forums\"],null]],[13],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `showHeading` property path was used in the `discourse/plugins/discourse-coupa-people/discourse/templates/connectors/discovery-list-container-top/forum-heading.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.showHeading}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-coupa-people/discourse/templates/connectors/discovery-list-container-top/forum-heading.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});